.unlockPackageForm-wrap {
  display: flex;
  min-height: 100vh;
  font-size: 12px;
  text-align: center; }

.unlockPackageForm-container {
  max-width: 580px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 15px 40px; }
  @media only screen and (min-width: 580px) {
    .unlockPackageForm-container {
      max-width: 492px; } }

.form-box {
  width: 100%;
  *height: 329px;
  padding: 40px 68px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(33, 55, 64, 0.16);
  background-color: #ffffff;
  margin-bottom: 24px; }
  .form-box .header {
    font-size: 19px;
    font-weight: bold;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #183928;
    text-align: center;
    width: 100%;
    padding: 16px; }
  .form-box .ant-form-item {
    margin-bottom: 0px; }
    .form-box .ant-form-item .codeInput {
      font-size: larger;
      text-align: center; }
  .form-box p {
    opacity: 0.5;
    font-size: 14px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #223841;
    text-align: center; }
  .form-box label {
    opacity: 0.5;
    font-size: 12px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #223841; }
  .form-box input[type="text"], .form-box input[type="password"] {
    width: 100%;
    height: 48px;
    border-radius: 4px;
    border: solid 1px rgba(33, 55, 64, 0.49); }
