.sessions-list-feature-wrapper {
    padding-top: 20px;

    h2 {
        text-align: left;
        font-size: 22px;
    }

    .sessions-list-wrapper {
        padding-bottom: 20px;
    }

    .session-list-row {
        display: flex;
        padding-bottom: 10px;
        font-size: 14px;
    }

    .session-list-row-header {
        font-weight: bold;
        font-size: 15px;
    }

    .session-list-col-name {
        flex: 1;
        text-align: left;
    }

    .session-list-col-status {
        width: 140px;
        text-align: left;
    }

    .session-list-col-availability {
        width: 110px;
        text-align: left;
    }

    .session-list-col-actions {
        width: 40px;
        text-align: right;
    }

    .page-link {
        color: blue;
        cursor: pointer;
    }
}