.packages-page-wrap {
    display: block;
    *min-height: 100vh;
    font-size: 12px;
    text-align: center;
    .top-bar {
        width: 100%;
        height: 56px;
        background-color: #183928;
        display: block;
    }
}

.packages-page-container {
    display: block;
    height: inherit;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
    @media only screen and (max-width: 580px) {
        max-width: 492px;
    }

    .display-area {
        width: 100%;
        padding: 10px 212px;
        @media only screen and (max-width: 580px) {
            padding: 0px;
        }

        .paginationContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 25px;
        }
    }
    .title {
        font-size: 26px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        text-align: center;
        display: inline-block;
        padding: 12px;
    }

    .resource-box {
        display: block;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(33, 55, 64, 0.16);
        background-color: #ffffff;
        margin-bottom: 24px;
        text-decoration-color: #183928;
        .image {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 208px;
            overflow-y: hidden;
            img {
                color: #183928;
                max-width: 100%;
                max-height: 100%;
            }
        }
        .title {
            display: block;
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            line-height: normal;
            letter-spacing: normal;
            color: #183928;
            text-align: left;
            padding: 10.5px 17px;
        }
    }
}