.canvas-fullscreen {
  position: fixed;
  top: 0;
  left: 0;
  overflow: hidden;
}
.exit-fullscreen-button {
  width: 120px;
  height: 35px;
  border-radius: 5px;
  background-color: rgba(255, 0, 0, 0.8);
  color: #FFFFFF;
  text-align: center;
  line-height: 35px;
  z-index: 999999;
  position: fixed;
  top: 25px;
  right: 40px;
  cursor: pointer;
}
