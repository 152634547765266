.resource-page-cache-hide {
    opacity: 0.4;
    /*display: none !important;*/
}

.vimeoContainer {
    width: 100%;
    height: 100%;
    .videoContainer {
        background-image: linear-gradient(to bottom,#18252f, #141719);
        width: 100%;
        height: 101%;

        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;


        .carousel-wrapper {
            height: 90%;
            width: 95%;
        }
        .carousel,
        .carousel > .slider-wrapper,
        .carousel > .slider-wrapper > .slider,
        .carousel > .slider-wrapper > .slider > .slide > div {
          height: 100%;
          width: 95%;
        }

        .react-player {
            div {
                iframe {
                    margin-left: 5%;
                    height: 100%;
                }
            }
        }
    }

    .buttonContainer {
        position: relative;
        padding-top: 70vh;
        padding-left: 40vh;
        padding-right: 40vh;
        display: flex;
        justify-content: space-between;

        .button {
            background-color: white;
            font-weight: bold;
        }
    }
}