.externalResourcePage-wrap {
    display: block;
    *min-height: 100vh;
    font-size: 12px;
    text-align: center;
    .top-bar {
        width: 100%;
        height: 56px;
        background-color: #183928;
        display: block;
    }
}