.formatted-text-wrapper {
    text-align: left;
    font-size: 14px;

    h1 {
        font-size: 26px;
    }
    h2 {
        font-size: 23px;
    }
    h3 {
        font-size: 20px;
    }
    h4 {
        font-size: 17px;
    }
    h5 {
        font-size: 15px;
    }
}
