.materials-block-wrapper {
    padding-top: 20px;

    h2 {
        text-align: left;
        font-size: 22px;
    }

    .materials-list-wrapper {}

    .materials-item-wrapper {
        display: flex;
        margin: 10px 0;
        padding: 5px 0;
    }

    .material-name {
        font-size: 14px;
        padding-right: 10px;
    }

    .padding {
        flex: 1;
    }

    .info-wrapper {
        display: flex;
        align-items: center;
    }

    .material-button {
        margin-left: 12px;
        padding: 0 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        color: #6AA84F;

        &:hover {
            background-color: #d4d4d4;
        }


        &.disabled {
            color: grey;
            cursor: not-allowed;
        }

        .icon {
            margin-left: 4px;
        }
    }

    p {
        padding: 0;
        margin: 0;
    }
}