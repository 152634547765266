.top-headers {
    background-color: #183928;
}

.background-image-wrapper {
    position: absolute;
    width: 100%;
    overflow: hidden;
    z-index: -200;

    img {
        position: relative;
        width: 100%;
    }
}

.session-bar {
    height: 50px;
    display: flex;
    width: 100%;
    background-color: white;
    box-shadow: 1px 1px 5px #00000055;

    .title {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
        h1 {
            display: inline;
            padding: 0;
            margin: 0;
            padding-right: 8px;
            color: #183928;
            font-weight: normal;
        }
    }

    .header-container {
        flex: 2;
    }

    .navigation-button-container {
        flex: 1;
        color: #183928;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;

        .nav-button {
            cursor: pointer;
        }

        .not-yet {
            cursor: not-allowed;
        }
    }
}

    .top-bar {
.resource-page-container {
    display: flex;
    justify-content: center;
        .title {
            text-align: left;
            width: 100%;
        }

        .back-link {
            padding-right: 12px;
        }
    }

}