@import url(~antd/dist/antd.css);
@font-face {
  font-family: "Halis Regular";
  src: url("../assets/fonts/HalisGR-Regular.otf"); }

@font-face {
  font-family: "Halis Bold";
  src: url("../assets/fonts/HalisGR-Bold.otf"); }

body {
  background-color: #f9f9f9;
  font-family: 'Halis Regular', 'Lato', sans-serif; }
  body .logo-heading {
    font-size: 26px;
    color: #6c6c6c;
    margin-top: 10px;
    margin-bottom: 30px; }
  body #animation-canvas {
    z-index: 99999; }

.menu-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-image: url("../assets/images/menu-icon.png");
  background-size: contain; }

.reload-icon {
  display: inline-block;
  width: 30px;
  height: 30px;
  object-fit: contain;
  background-image: url("../assets/images/reload-icon.png");
  background-size: contain; }

.replay-icon {
  display: inline-block;
  width: 35px;
  height: 35px;
  object-fit: contain;
  background-image: url("../assets/images/replay-icon.png");
  background-size: contain;
  cursor: pointer; }

.fullscreen-icon {
  display: inline-block;
  width: 16px;
  height: 16px;
  object-fit: contain;
  background-image: url("../assets/images/fullscreen-icon.png");
  background-size: contain; }

button.ant-btn-primary {
  min-width: 240px;
  height: 56px;
  border-radius: 28px;
  background-color: #c4d600;
  border-color: #c4d600;
  font-size: 18px;
  font-weight: 900;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #183928;
  z-index: 1; }
  button.ant-btn-primary:hover {
    background-color: #d5e700;
    border-color: #d5e700;
    color: #183928; }
  button.ant-btn-primary:focus {
    background-color: #d5e700;
    border-color: #d5e700;
    color: #183928; }

a {
  text-decoration: underline;
  font-size: 14px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #0071d6; }

.ant-pagination a {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100% !important; }

.page-wrap {
  display: flex;
  min-height: 100vh;
  font-size: 12px;
  text-align: center; }

.page-container {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 40px 15px 40px; }
  @media only screen and (max-width: 580px) {
    .page-container {
      max-width: 492px; } }

h1 {
  font-family: 'Halis Bold', 'Lato', sans-serif;
  font-size: 25px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #183928; }

.animation-box {
  width: 200px;
  height: 120px;
  margin-left: 10px; }
  .animation-box:first-child {
    margin-left: 0px; }
  .animation-box .animation-image {
    width: 100%; }
    .animation-box .animation-image img {
      width: 100%; }
  .animation-box .animation-title {
    background-color: #FFF;
    text-align: center;
    padding: 5px; }

.resource-page-wrap {
  display: block;
  *min-height: 100vh;
  font-size: 12px;
  text-align: center; }
  .resource-page-wrap .top-bar {
    width: 100%;
    height: 56px;
    background-color: #183928;
    display: block; }

.resource-page-container {
  display: block;
  height: inherit;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  padding: 0px 15px; }
  @media only screen and (max-width: 580px) {
    .resource-page-container {
      max-width: 492px; } }
  .resource-page-container .display-area {
    width: 100%;
    padding: 10px 212px; }
    @media only screen and (max-width: 580px) {
      .resource-page-container .display-area {
        padding: 0px; } }
  .resource-page-container .title {
    font-size: 26px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-align: center;
    display: inline-block;
    padding: 12px; }
  .resource-page-container .resource-box {
    display: block;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(33, 55, 64, 0.16);
    background-color: #ffffff;
    margin-bottom: 24px;
    text-decoration-color: #183928; }
    .resource-page-container .resource-box .image {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 208px;
      overflow-y: hidden; }
      .resource-page-container .resource-box .image img {
        color: #183928;
        max-width: 100%;
        max-height: 100%; }
    .resource-page-container .resource-box .title {
      display: block;
      width: 100%;
      height: 40px;
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #183928;
      text-align: left;
      padding: 10.5px 17px; }

.custom-input {
  height: 48px;
  border-radius: 4px;
  border: solid 1px rgba(33, 55, 64, 0.49); }

.ant-spin-dot i {
  background-color: #183928; }

.ant-spin-text {
  color: #183928;
  font-size: 20px; }
