.file-storage-page-wrap {
    a {
        text-decoration: none;
    }
}

.file-storage-page-wrap {
    display: block;
    *min-height: 100vh;
    font-size: 12px;
    text-align: center;
    .top-bar {
        width: 100%;
        height: 56px;
        background-color: #183928;
        display: block;
    }
}

.file-storage-page-container {
    display: block;
    height: inherit;
    max-width: 1280px;
    width: 100%;
    margin: 0 auto;
    padding: 0px 15px;
    @media only screen and (max-width: 580px) {
        max-width: 492px;
    }
    .display-area {
        width: 100%;
        padding: 10px 212px;
        @media only screen and (max-width: 580px) {
            padding: 0px;
        }

        .filtersRow {
            margin: 30px auto;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .searchBtn {
                margin-left: 10px;
                background-color: #c4d600;
                border-radius: 20px;
                color: #183928;
            }
            .clearBtn {
                margin-left: 10px;
                border-radius: 20px;
            }
        }

        .paginationContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 25px;
        }
    }
    .title {
        font-size: 26px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        display: inline-block;
        padding: 12px;
        text-align: center;
    }
    .file-storage-box {
        display: block;
        border-radius: 4px;
        box-shadow: 0 5px 10px 0 rgba(33, 55, 64, 0.16);
        background-color: #ffffff;
        margin-bottom: 40px;
        .image {
            display: block;
            width: 100%;
            height: auto;
            img {
                padding-top: 20px;
                max-height: 160px;
            }
        }
        .title {
            display: block;
            width: 100%;
            height: 40px;
            font-size: 16px;
            font-weight: bold;
            font-style: normal;
            font-stretch: normal;
            text-align: center;
            line-height: normal;
            letter-spacing: normal;
            color: #183928;
            padding: 10.5px 17px;
        }
    }

    .tagContainer {
        position: absolute;
        margin-top: -35px;
    }
}
.custom-input {
    height: 48px;
    border-radius: 4px;
    border: solid 1px rgba(33, 55, 64, 0.49);
}
.ant-spin-dot i {
    background-color: #183928 ;
}
.ant-spin-text {
    color: #183928 ;
    font-size: 20px;
}