.sharePackageDetails {
    max-width: 580px;
    width: 100%;
    margin: 0 auto;
    padding: 40px 15px 40px;
    @media only screen and (min-width: 580px) {
        max-width: 492px;
    }
}

.sharePackageElement {
    display: flex;
    margin-bottom: 20px;
}