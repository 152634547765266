.title {
  color: green; }

.drawer-link-item {
  padding: 21px;
  border-bottom: 1px solid #ebebeb; }
  .drawer-link-item a {
    font-size: 18px;
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #183928;
    text-decoration: none; }
  .drawer-link-item .icon {
    width: 16px;
    height: 16px;
    margin-right: 16px; }
